import React, {useState} from 'react';
import styles from './OutlinedInput.module.scss';
import {P} from '../text/Text';
import moment from 'moment';
import {Image} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import showIcon from './assets/icons/eye.svg';
import hideIcon from './assets/icons/eye-lock.svg';
import infoIcon from './assets/icons/info.png';

const LANGS = {
    AR: 'AR',
    EN: 'EN'
};

type Props = {
    type?: React.HTMLInputTypeAttribute;
    title?: string;
    name?: string;
    handleClick?: (name?: string) => void;
    value?: any;
    setValue?: (e: React.ChangeEvent<any>) => void;
    width?: string;
    options?: string[] | {title: string; value: string}[];
    placeholder?: string;
    disabled?: boolean;
    error?: string;
    lang?: 'EN' | 'AR';
    min?: string | number;
    max?: string | number;
    autoComplete?: string;
    className?: string;
    required?: boolean;
    info?: string;
};

const OutlinedInput = ({
    type = 'text',
    title,
    name,
    handleClick,
    value,
    setValue,
    width,
    options,
    placeholder,
    disabled,
    error,
    lang = 'AR',
    min,
    max,
    autoComplete = 'on',
    className = '',
    required,
    info,
    ...props
}: Props): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const handleChange = (e: React.ChangeEvent<any>) => {
        setValue && setValue(e);
    };

    const toggleShowPassword = () => setShowPassword((prev) => !prev);
    const handleType = () => (type === 'password' && showPassword ? 'text' : type);
    const randomTooltipId = generateId();

    return (
        <div style={{width: width ? width : '100%'}} className={`${styles[lang]} ${styles['input-container']} `}>
            <div className={styles.input}>
                <label className={`${styles['pure-material-textfield-outlined']} ${styles[lang]}`}>
                    <RenderInput
                        name={name}
                        value={value}
                        handleChange={(value) => handleChange(value)}
                        type={handleType()}
                        placeholder={placeholder}
                        options={options}
                        disabled={disabled}
                        handleClick={handleClick}
                        lang={lang}
                        min={min}
                        max={max}
                        autoComplete={autoComplete}
                        className={className}
                        info={info}
                        {...props}
                    />
                    <span>
                        {title}
                        {required && <span className={styles.required}>*</span>}
                    </span>
                </label>
                {type === 'password' && (
                    <Image
                        src={showPassword ? hideIcon : showIcon}
                        alt='show'
                        onClick={toggleShowPassword}
                        className={`${styles['show-password']} ${styles[lang]}`}
                    />
                )}
                {error && (
                    <P type='p2' other='error'>
                        * {error}
                    </P>
                )}
            </div>
            {info && (
                <a data-for={randomTooltipId} data-tip={info} data-iscapture='true' className={styles.info}>
                    <img src={infoIcon} />
                </a>
            )}
            <ReactTooltip id={randomTooltipId} place={'bottom'} type={'info'} effect={'solid'} multiline={true} />
        </div>
    );
};

export default OutlinedInput;

const RenderInput = ({
    type,
    value,
    options,
    placeholder,
    disabled,
    handleChange,
    name,
    handleClick,
    lang,
    min,
    max,
    autoComplete,
    className,
    ...props
}: Props & {handleChange: (e: React.ChangeEvent<any>) => void}) => {
    const checkHandleClick = () => {
        if (handleClick) {
            handleClick(name);
        }
    };
    const handleMinMax = (value) => {
        const today = moment().locale('en').format('YYYY-MM-DD');
        return value === 'today' ? today : value;
    };
    if (type === 'select') {
        return (
            <select
                className={className}
                placeholder={placeholder}
                value={value && value}
                onChange={(e) => handleChange(e)}
                disabled={disabled}
                name={name}
                {...props}>
                <option>{placeholder ? placeholder : lang === 'EN' ? 'Select' : 'اختار'}</option>
                {options &&
                    options.map((option: string | {title: string; value: string}, index) => {
                        if (typeof option === 'string') {
                            return <option key={index}>{option}</option>;
                        } else {
                            return (
                                <option key={index} value={option.value}>
                                    {option.title}
                                </option>
                            );
                        }
                    })}
            </select>
        );
    } else if (type === 'textarea') {
        return (
            <textarea
                className={className}
                value={value && value}
                onChange={(e) => handleChange(e)}
                rows={5}
                placeholder={placeholder}
                disabled={disabled}
                name={name}
                autoComplete={autoComplete}
                {...props}
            />
        );
    }

    return (
        <input
            className={className}
            placeholder=' '
            value={value && value}
            type={type}
            onChange={(e) => handleChange(e)}
            disabled={disabled}
            name={name}
            onClick={checkHandleClick}
            min={handleMinMax(min)}
            max={handleMinMax(max)}
            autoComplete={autoComplete}
            {...props}
        />
    );
};

function generateId(size: number = 10) {
    const result: string[] = [];
    const hexRef: string[] = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z'
    ];

    for (let n = 0; n < size; n++) {
        result.push(hexRef[Math.floor(Math.random() * hexRef.length)]);
    }
    return result.join('');
}
