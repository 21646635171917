import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import './Gallery.scss'
import ImageGallery from 'react-image-gallery'
import {Button, Image} from 'react-bootstrap'
import closeIcon from './assets/icons/close.svg'
import trashIcon from './assets/icons/trash.svg'
import rightIcon from './assets/icons/right.svg'
import leftIcon from './assets/icons/left.svg'
import zoomInIcon from './assets/icons/zoomIn.svg'
import zoomOutIcon from './assets/icons/zoomOut.svg'

import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch'

const Gallery = ({images, closeAction, canRemove, removeAction}) => {
    const [currentElement, setCurrentElement] = useState(0)

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                closeAction()
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [closeAction])

    const rightNaV = (handleClick) => {
        return (
            <Button className='image-gallery-icon image-gallery-right-nav' onClick={() => handleClick()}>
                <Image alt='close-icon' src={rightIcon} />
            </Button>
        )
    }
    const leftNaV = (handleClick) => {
        return (
            <Button className='image-gallery-icon image-gallery-left-nav' onClick={() => handleClick()}>
                <Image alt='close-icon' src={leftIcon} />
            </Button>
        )
    }

    const renderElement = (element) => {
        // console.log('elment: ', element)

        // setCurrentElement(element)
        return (
            <TransformWrapper
                defaultScale={1}
                options={{limitToWrapper: true, limitToBounds: true, transformEnabled: true, maxPositionX: 0}}
                wheel={{step: 40}}
                pan={{
                    velocity: true
                }}>
                {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                    <>
                        <ZoomIcons zoomIn={zoomIn} zoomOut={zoomOut} />
                        <TransformComponent>
                            {element.type === 'pdf' ? (
                                <iframe src={element.original} height='800px' width='100%' />
                            ) : (
                                <Image src={element.original} className='image-gallery-image' />
                            )}
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
        )
    }

    return (
        <div className='gallery-container'>
            <>
                <ActionsIcons
                    closeAction={() => closeAction()}
                    canRemove={canRemove}
                    removeAction={removeAction}
                    currentElement={currentElement}
                />
                <ImageGallery
                    isRTL={true}
                    items={images}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    renderRightNav={rightNaV}
                    renderLeftNav={leftNaV}
                    renderItem={renderElement}
                    infinite={false}
                    disableSwipe={true}
                    onBeforeSlide={(idx) => setCurrentElement(idx)}
                />
            </>
        </div>
    )
}

export default Gallery

const ZoomIcons = ({zoomIn, zoomOut}) => {
    return (
        <div className='gallery-zoom-btns-container'>
            <Button className='zoom-out-btn btn' onClick={zoomOut}>
                <Image alt='trash-icon' src={zoomOutIcon} />
            </Button>
            <Button className='zoom-in-btn btn' onClick={zoomIn}>
                <Image alt='close-icon' src={zoomInIcon} />
            </Button>
        </div>
    )
}

const ActionsIcons = ({closeAction, canRemove, removeAction, currentElement}) => {
    return (
        <div className='gallery-actions-btns-container'>
            {canRemove && (
                <Button className='trash-btn btn' onClick={() => removeAction(currentElement)}>
                    <Image alt='trash-icon' src={trashIcon} />
                </Button>
            )}
            <Button className='close-btn btn' onClick={() => closeAction()}>
                <Image alt='close-icon' src={closeIcon} />
            </Button>
        </div>
    )
}

Gallery.propTypes = {
    images: PropTypes.array,
    closeAction: PropTypes.func,
    canRemove: PropTypes.bool,
    removeAction: PropTypes.func
}
